import Layout from "@/components/Layout/Layout";
import QuestList from "./components/Quests/Quests";
import Slide from "./components/Slide/Slide";
import { CareerListWrapper } from "./index.style";

export default function CareerPage() {
  return (
    <Layout>
      <CareerListWrapper className="min-h-screen w-full flex-1 flex-col mt-10 md:mt-16">
        <div className="max-w-[1440px] px-3 md:px-20 mx-auto">
          <div className="font-bold text-3xl md:text-6xl text-center mt-20 font-orbitron">
            Careers
          </div>
          {/* <div className="md:text-lg text-xl font-medium text-center mt-6 text-[#B2B2B2]">
            Join the Challenge and Unlock Rewards Now
          </div> */}
        </div>
        <Slide />
        <QuestList />
      </CareerListWrapper>
      {/* <ModalEnterCode>
        <Button variant="outline">Click</Button>
      </ModalEnterCode> */}
    </Layout>
  );
}
