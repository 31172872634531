import useCareer from "@/hooks/useCareer";
import { Career } from "@/types/apis/career";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuestListWrapper } from "./Quests.style";

interface Tab {
  id: string;
  title: string;
}

const tabs: Tab[] = [
  {
    id: "newest",
    title: "Newest",
  },
];

const LIMIT = 10;

const QuestList: React.FC = () => {
  const tabsRef = useRef<(HTMLButtonElement | null)[]>(
    Array(tabs.length).fill(null)
  );
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [filterTab, setFilterTab] = useState("newest");
  const [listCareerQuest, setListCareerQuest] = useState<Career[]>([]);
  const [totalListQuest, setTotalListQuest] = useState<number>(0);
  const [offset, setOffset] = useState(0);
  const { getListCareer } = useCareer();

  const getListCareerQuest = useCallback(async () => {
    const res = await getListCareer(filterTab, LIMIT, offset);
    setListCareerQuest((prev) => [...prev, ...res.careersList]);
    setTotalListQuest(res.totals);
  }, [filterTab, offset]);

  useEffect(() => {
    getListCareerQuest();
  }, [getListCareerQuest]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  return (
    <QuestListWrapper className="max-w-[1440px] mt-[64px] mx-auto px-3 md:px-20">
      <div className="relative">
        <div className="flex flex-row space-x-7 mx-3 md:mx-0 md:space-x-3">
          {tabs.map((tab: Tab, idx: number) => (
            <button
              key={tab.id}
              ref={(el) => (tabsRef.current[idx] = el)}
              className={clsx(
                "pt-2 pb-3 font-be-vn font-medium text-xl transition-all duration-300 w-auto basis-auto md:basis-[100px]",
                {
                  "text-[#ff8412]": idx === activeTabIndex,
                  "text-[#B2B2B2]": idx !== activeTabIndex,
                }
              )}
              onClick={() => {
                setActiveTabIndex(idx);
                setFilterTab(tab.id);
                setListCareerQuest([]);
                setOffset(0);
              }}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <span
          className="absolute bottom-0 block h-1 bg-[#ff8412] transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
      <div className="flex flex-col md:flex-wrap md:flex-row gap-6">
        {listCareerQuest?.map((career, idx) => (
          <div
            key={`card-${career.ID}_${idx}`}
            onClick={() => navigate(`/careers/${career.ID}`)}
            className={clsx(
              "quest-item cursor-pointer",
              "mt-[32px] basis-[32%] flex flex-col space-y-5 md:!p-[20px] px-5 py-5 bg-[#1C1C1C] min-h-[530px]",
              "rounded-[24px] border",
              "border-[#1C1C1C] hover:border-[#ff8412]"
            )}
          >
            <div className="w-auto h-auto relative">
              <img
                className="w-full h-full md:h-[250px] object-cover rounded-lg"
                src={career.ImageBanner}
                alt="CourseImg"
              />
              <div className="absolute top-2.5 right-2.5 flex flex-row gap-2.5">
                {career.RemoteTag.split(",").map((item, idx) => (
                  <div
                    className="px-2 py-0.5 w-auto border border-white backdrop-blur-[20px] rounded-lg"
                    key={`${item}_${idx}`}
                  >
                    <span className="text-sm font-medium font-be-vn text-white">
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full">
              <div className="bg-white rounded-lg px-5 py-2 w-[max-content]">
                <span className="text-sm font-normal font-be-vn text-[#030303]">
                  {career.Skills.split(",").join(" | ")}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="text-xl font-semibold text-white font-be-vn line-clamp-2">
                {career.Title}
              </span>
              <div
                className="text-base font-normal text-[#B4B4B4] font-be-vn line-clamp-4"
                dangerouslySetInnerHTML={{ __html: career.Description }}
              />
            </div>
          </div>
        ))}
      </div>
      {listCareerQuest.length < totalListQuest && (
        <div
          className="mt-[46px] mb-[120px] flex justify-center"
          onClick={() => setOffset((prev) => (prev += LIMIT))}
        >
          <div className="flex cursor-pointer gap-x-3 items-center">
            <p className="font-avenir font-bold text-2xl">SEE MORE</p>
            <ArrowCircleRightIcon className=" rotate-90" />
          </div>
        </div>
      )}
    </QuestListWrapper>
  );
};

export default QuestList;
