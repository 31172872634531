import { axiosPublic } from "@/http/axiosPublic";
import { Career } from "@/types/apis/career";

const getCarrer = async (
  type: string,
  limit: number,
  offset: number
): Promise<any> => {
  const res = await axiosPublic.get<any>(
    `/careers/list/${type}/${limit}/${offset}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  return res?.data;
};

export const getDetailCareer = async (careerID: number): Promise<Career> => {
  return axiosPublic.get(`/careers/${careerID}`).then((res) => res.data.career);
};

const careerService = {
  getCarrer,
  getDetailCareer,
};

export default careerService;
