import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import useCareer from "@/hooks/useCareer";
import { Career } from "@/types/apis/career";
import { useCallback, useEffect, useState } from "react";
import { SlideWrapper } from "./Slide.style";
import SlideItem from "./SlideItem";

const pagination = {
  clickable: true,
  renderBullet: function (index: number, className: string) {
    return `<div class="${className}"></div>`;
  },
};

export interface Courses {
  PartnerName: string;
  Title: string;
  Description: string;
  PartnerAvatar: string;
  ImageBanner: string;
  PointReward: number;
  ID: number;
}

const Slide: React.FC = () => {
  const [listCareerSlide, setListCareerSlide] = useState<Career[]>([]);
  const { getListCareer } = useCareer();

  const getListCareerSlide = useCallback(async () => {
    const res = await getListCareer("slide", 10, 0);
    setListCareerSlide(res.careersList);
  }, []);

  useEffect(() => {
    getListCareerSlide();
  }, []);

  return (
    <SlideWrapper className="px-3 md:px-10 [&_.swiper-button-prev]:hidden md:[&_.swiper-button-prev]:block [&_.swiper-button-next]:hidden md:[&_.swiper-button-next]:block">
      <Swiper
        loop={true}
        grabCursor={true}
        navigation={true}
        pagination={pagination}
        modules={[Autoplay, Navigation, Pagination]}
        // autoplay={{
        //   delay: 5000,
        //   disableOnInteraction: false,
        // }}
      >
        {listCareerSlide?.map((career) => (
          <SwiperSlide key={`slide-campaign-${career.ID}`}>
            <SlideItem career={career} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SlideWrapper>
  );
};

export default Slide;
