import { cn } from "@/lib/utils";
import { Career } from "@/types/apis/career";
import { useNavigate } from "react-router-dom";

const SlideItem = ({ career }: { career: Career }) => {
  const navigate = useNavigate();

  const { PartnerName, Title, ShortDescription, PartnerAvatar, ImageBanner } =
    career;

  return (
    <div
      onClick={() => navigate(`/careers/${career.ID}`)}
      className="slide-item px-0  md:px-20"
    >
      <div className="slide-content flex min-h-[400px] !flex-col md:!flex-row space-y-10 md:space-y-0 items-center justify-center">
        <div className="slide-info basis-[40%] flex flex-col justify-center px-0 md:px-[24px]">
          <div className="flex items-center gap-x-4">
            <img
              className="w-16 h-16 rounded-full"
              src={PartnerAvatar}
              alt=""
            />
            <p className="font-avenir font-heavy text-2xl text-white">
              {PartnerName}
            </p>
          </div>
          <div
            className={cn(
              "mt-[16px] font-be-vn font-bold leading-[40px] text-[32px] tracking-[0.2%] uppercase text-white",
              "line-clamp-3"
            )}
          >
            {Title}
          </div>
          <p className="mt-[24px] font-be-vn font-normal leading-[22px] text-sm text-white uppercase">
            {ShortDescription}
          </p>
          <div className="w-fit cursor-pointer mt-[50px] bg-black rounded-[12px] px-[20px] py-[10px] flex items-center gap-x-[16px]">
            {/* <img src="/images/token-img.png" alt="" /> */}
            <p className="font-avenir text-gradient font-bold text-2xl align-middle">
              Tham Gia
            </p>
          </div>
        </div>
        <div className="hidden md:block slide-img basis-[60%] rounded-[16px] overflow-hidden justify-center items-center">
          <object
            className="w-auto h-[485px] object-cover"
            data={ImageBanner}
            type="image/png"
          >
            <img
              className="h-full w-full object-fill"
              src="/images/quest-img.png"
              alt="bg"
            />
          </object>
        </div>
        <div className="md:!hidden slide-img basis-[60%] rounded-[16px] overflow-hidden flex justify-center items-center">
          <img src="/images/quest-img-mo.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default SlideItem;
