import LogoIcon from "@/assets/icons/LogoIcon";
import MenuIcon from "@/assets/icons/MenuIcon";
import CVIcon from "@/assets/images/cvIcon.svg";
import LogoutIcon from "@/assets/images/logoutIcon.svg";
import BorderDashed from "@/assets/images/profile/borderDashed.svg";
import DropdownIcon from "@/assets/images/profile/dropdownIcon.svg";
import ProfileIcon from "@/assets/images/profileIcon.svg";
import WalletIcon from "@/assets/images/walletIcon.svg";
import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import useAddress from "@/hooks/useAddress";
import { cn } from "@/lib/utils";
import { useWeb3Auth } from "@/providers/Web3AuthProvider";
import { useProfile } from "@/store/profileStore";
import addressShorten from "@/utils/addressShorten";
import { FaSignOutAlt } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
export default function Header() {
  const { login, logout } = useWeb3Auth();
  const { address } = useAddress();
  const { userCVProfile } = useProfile();
  const navigate = useNavigate();

  const currentPath = window.location.pathname;

  window.onclick = function (event) {
    if (!(event?.target as any)?.matches(".dropbtn")) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  return (
    <>
      <div className="h-20 items-center hidden xl:flex fixed z-[10] top-0 bg-black w-[100vw] left-0">
        <div className="max-w-[1440px] w-full px-20 mx-auto flex justify-between items-center">
          <div
            className="text-2xl cursor-pointer"
            onClick={() => navigate("/")}
          >
            <LogoIcon />
          </div>
          <div className="flex items-center bg-[#1C1C1C] rounded-xl h-[50px] px-1">
            <button
              onClick={() => {
                navigate("/nft");
              }}
              className={cn(
                "text-sm font-normal font-orbitron py-2.5 px-6 w-[135px]",
                currentPath === "/nft" &&
                  "bg-linear-orange border border-[#030303] text-[#030303] rounded-xl"
              )}
            >
              Mint
            </button>
            <button
              onClick={() => {
                navigate("/events");
              }}
              className={cn(
                "text-sm font-normal font-orbitron py-2.5 px-6 w-[135px]",
                currentPath === "/events" &&
                  "bg-linear-orange border border-[#030303] text-[#030303] rounded-xl"
              )}
            >
              Events
            </button>
            <button
              onClick={() => {
                navigate(`/courses`);
              }}
              className={cn(
                "text-sm font-normal font-orbitron py-2.5 px-6 w-[135px]",
                currentPath.includes("/courses") &&
                  "bg-linear-orange border border-[#030303] text-[#030303] rounded-xl"
              )}
            >
              Courses
            </button>
            <button
              onClick={() => {
                // window.open(
                //   ENVS.REACT_APP_APP_ENDPOINT + "/campaigns",
                //   "_blank"
                // );
                navigate("/campaigns");
              }}
              className={cn(
                "text-sm font-normal font-orbitron py-2.5 px-6 w-[135px]",
                (currentPath.includes("/campaigns") || currentPath === "/") &&
                  "bg-linear-orange border border-[#030303] py-2.5 px-6 text-[#030303] rounded-xl"
              )}
            >
              Campaigns
            </button>

            <button
              onClick={() => {
                navigate("/careers");
              }}
              className={cn(
                "text-sm font-normal font-orbitron py-2.5 px-6 w-[135px]",
                currentPath.includes("/careers") &&
                  "bg-linear-orange border border-[#030303] text-[#030303] rounded-xl"
              )}
            >
              Careers
            </button>
          </div>
          <div className="flex relative">
            {address && (
              <div
                className="flex gap-1 border cursor-pointer dropbtn border-[#1C1C1C] rounded-lg px-5 py-3 flex-row space-x-3 items-center"
                onClick={() =>
                  window.document
                    ?.getElementById("myDropdown")
                    ?.classList.toggle("show")
                }
              >
                <span className="dropbtn">{addressShorten(address)}</span>
                <div className="dropbtn flex flex-row items-center space-x-1">
                  <div className="dropbtn border border-[#212121] rounded-[4px]">
                    <img
                      className="dropbtn w-6 h-6 rounded-[4px]"
                      src={
                        userCVProfile.profileImage ||
                        "https://w7.pngwing.com/pngs/789/888/png-transparent-computer-icons-login-person-user-avatar-log-smiley-desktop-wallpaper-sign.png"
                      }
                      alt="avatar"
                    />
                  </div>
                  <div className="dropbtn">
                    <img
                      className="dropbtn w-4 h-4"
                      src={DropdownIcon}
                      alt="DropdownIcon"
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              id="myDropdown"
              className="dropdown-content hidden flex-col space-y-4 absolute border border-[#212121] rounded-lg bg-black top-[60px] right-0 min-w-[160px] w-[190px] px-3 py-3 shadow-[0px_8px_16px_0px_rgba(0,0,0,0.2)] z-[1]"
            >
              <div
                className="flex flex-row space-x-3 cursor-pointer"
                onClick={() => {
                  navigate(`/profile/${address}`);
                }}
              >
                <img className="w-6 h-6" src={ProfileIcon} alt="ProfileIcon" />
                <span className="text-sm font-normal font-be-vn text-[#838383]">
                  Profile
                </span>
              </div>
              <img
                src={BorderDashed}
                alt="BorderDashed"
                className="block w-full h-[3px]"
              />
              <div
                className="flex flex-row space-x-3 cursor-pointer"
                onClick={() => {
                  navigate(`/profile/${address}`);
                }}
              >
                <img className="w-6 h-6" src={CVIcon} alt="CVIcon" />
                <span className="text-sm font-normal font-be-vn text-[#838383]">
                  My Resume
                </span>
              </div>
              <img
                src={BorderDashed}
                alt="BorderDashed"
                className="block w-full h-[3px]"
              />
              <div
                className="flex flex-row space-x-3 cursor-pointer"
                onClick={() => {
                  navigate(`/wallet`);
                }}
              >
                <img className="w-6 h-6" src={WalletIcon} alt="WalletIcon" />
                <span className="text-sm font-normal font-be-vn text-[#838383]">
                  Wallet
                </span>
              </div>
              <img
                src={BorderDashed}
                alt="BorderDashed"
                className="block w-full h-[3px]"
              />
              <div
                className="flex flex-row space-x-3 cursor-pointer"
                onClick={logout}
              >
                <img className="w-6 h-6" src={LogoutIcon} alt="LogoutIcon" />
                <span className="text-sm font-normal font-be-vn text-[#838383]">
                  Log out
                </span>
              </div>
            </div>
            {!address && (
              <Button
                className="px-5 text-base  font-orbitron hover:scale-105 ease-in-out duration-150 transition"
                variant="outline"
                onClick={login}
              >
                Login
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="xl:hidden px-3 h-16 w-full flex items-center justify-between">
        <div className="text-2xl">
          <LogoIcon />
        </div>
        <Drawer direction="right">
          <DrawerTrigger
            // className={cn(
            //   "py-1 text-black bg-white hover:bg-neutral-100 hover:text-neutral-900 ",
            //   "shadow-[2px_2px_0px_#38FFF3] border border-black px-2 text-base"
            // )}
            asChild
          >
            <Button className="h-10 px-5 rounded-2xl" variant="outline">
              <MenuIcon />
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[100svh] bg-black rounded-0">
            <DrawerClose>
              <IoIosClose className="text-[42px] absolute right-3 top-3" />
            </DrawerClose>
            <DrawerHeader>
              <DrawerTitle>
                <LogoIcon />
              </DrawerTitle>
              <div className="flex flex-col gap-5 mt-5">
                <div
                  onClick={() => {
                    navigate("/nft");
                  }}
                  className="text-base font-medium font-orbitron"
                >
                  Mint
                </div>
                <div
                  onClick={() => {
                    navigate("/events");
                  }}
                  className="text-base font-medium font-orbitron"
                >
                  Events
                </div>
                <div
                  onClick={() => {
                    navigate(`/courses`);
                  }}
                  className="text-base font-medium font-orbitron"
                >
                  Courses
                </div>
                <div
                  onClick={() => {
                    navigate("/campaigns");

                    // window.open(
                    //   ENVS.REACT_APP_APP_ENDPOINT + "/campaigns",
                    //   "_blank"
                    // );
                  }}
                  className="text-base font-medium font-orbitron"
                >
                  Campaigns
                </div>

                <div
                  onClick={() => {
                    navigate("/careers");
                  }}
                  className="text-base font-medium font-orbitron"
                >
                  Careers
                </div>
              </div>
              {address && (
                <div className="flex gap-1 absolute left-3 right-3 bottom-6">
                  <Button
                    className="px-5 text-sm w-full"
                    variant="outline"
                    onClick={() => {
                      navigate(`/profile/${address}`);
                    }}
                  >
                    {addressShorten(address, 10, 10)}
                  </Button>
                  <Button
                    className="px-2 text-base"
                    variant="outline"
                    onClick={logout}
                  >
                    <FaSignOutAlt />
                  </Button>
                </div>
              )}
              {!address && (
                <Button
                  variant="outline"
                  onClick={login}
                  className="bottom-6 left-3 right-3 absolute"
                >
                  Connect Wallet
                </Button>
              )}
            </DrawerHeader>
          </DrawerContent>
        </Drawer>
      </div>
    </>
  );
}
