import { lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import CareerPage from "./pages/CareerPage";
import { lazyRetry } from "./utils/lazyRetry";
const LandingPage = lazy(() => lazyRetry(() => import("./pages/LandingPage")));
const CampaignPage = lazy(() =>
  lazyRetry(() => import("./pages/CampaignPage"))
);
const CareerDetailPage = lazy(() =>
  lazyRetry(() => import("./pages/CareerPage/Detail"))
);
const CourseDetailPage = lazy(() =>
  lazyRetry(() => import("./pages/CoursesPage/Detail"))
);
const CoursesPage = lazy(() => lazyRetry(() => import("./pages/CoursesPage")));
const EarnPage = lazy(() => lazyRetry(() => import("./pages/EarnPage")));
const NFTPage = lazy(() => lazyRetry(() => import("./pages/NFTPage")));
const ProfilePage = lazy(() => lazyRetry(() => import("./pages/ProfilePage")));
const WalletProfilePage = lazy(() =>
  lazyRetry(() => import("./pages/ProfileWallet"))
);
const ProfileNFTPage = lazy(() =>
  lazyRetry(() => import("./pages/ProfileNFTPage"))
);
// const ProfileCoursesPage = lazy(() =>
//   lazyRetry(() => import("./pages/ProfileCoursesPage"))
// );
const EventPage = lazy(() => lazyRetry(() => import("./pages/EventPage")));
const AuthSocialPage = lazy(() =>
  lazyRetry(() => import("./pages/AuthSocialPage"))
);

export const LandingPageRoutes = () => {
  return [
    { path: "/", element: <CampaignPage /> },
    { path: "/nft", element: <NFTPage /> },
    {
      path: "/profile/:id",
      element: <ProfilePage />,
    },
    { path: "/profile/:id/nft", element: <ProfileNFTPage /> },

    {
      path: "/campaigns",
      element: <CampaignPage />,
    },
    {
      path: "/courses",
      element: <CoursesPage />,
    },
    {
      path: "/careers",
      element: <CareerPage />,
    },
    { path: "/careers/:id", element: <CareerDetailPage /> },
    { path: "/courses/:id", element: <CourseDetailPage /> },
    {
      path: "/airdrop/auth-callback",
      element: <AuthSocialPage />,
    },
    { path: "/campaigns/:id", element: <EarnPage /> },
    { path: "/events", element: <EventPage /> },
    { path: "/wallet", element: <WalletProfilePage /> },
    { path: "*", element: <Navigate to="/" /> },
  ];
};
const Routes = () => {
  let routes: RouteObject[] = LandingPageRoutes();
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};
export default Routes;
