import careerService from "@/api/carrer.service";

export default function useCareer() {
  const getListCareer = async (type: string, limit: number, offset: number) => {
    try {
      const res = await careerService.getCarrer(type, limit, offset);
      return res;
    } catch (error) {
      console.log("🚀 ~ fetchingCareer ~ error:", error);
    }
  };

  return { getListCareer };
}
