import styled from "styled-components";

export const QuestListWrapper = styled.div`
  .quest-item {
    .div-button {
      /* border: 1px solid #000000; */
    }

    &:hover {
      .div-button {
        box-shadow: 4px 4px 0px 0px #40ffff;
      }
    }
  }
`;
