import styled from "styled-components";

export const SlideWrapper = styled.div`
  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    bottom: 0px;
  }

  .swiper-button-prev {
    background-image: url(/images/icons/arrow-circle-left.svg) !important;
    width: 64px;
    height: 64px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .swiper-button-next {
    background-image: url(/images/icons/arrow-circle-right.svg) !important;
    width: 64px;
    height: 64px;
    right: 0px;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-pagination-bullet {
    width: 66px;
    height: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    border: 1px solid #ffffff;

    transition: all 0.5s ease;
  }

  .swiper-pagination-bullet-active {
    background: linear-gradient(-89.28deg, #ff8412 -1.08%, #ff9e44 91.02%);
    border: none;
  }

  .slide-item {
    box-sizing: border-box;

    width: 100%;

    .slide-content {
      width: 100%;

      padding: 16px;

      background: linear-gradient(-89.28deg, #ff8412 -1.08%, #ff9e44 91.02%);
      border: 2px solid #ffffff;
      border-radius: 32px;

      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  @media screen and (max-width: 992px) {
    .swiper-pagination-bullet {
      width: 39px;
    }

    .slide-item {
      width: 99%;
      .slide-content {
        padding: 40px 16px 20px 16px;
      }
    }

    .slide-img {
      width: 107%;
    }
  }
`;
